@import "lib/ui/themes/variables";
@import "variables.module";

:root {
  #{--fontsize-base}: $fontsize-base;
  #{--primary-color}: $primary-color;

  @media (min-width: $breakpoint-xxl + px) {
    #{--fontsize-base}: $fontsize-base-xxl;
  }
  @media (min-width: $breakpoint-3xl + px) {
    #{--fontsize-base}: $fontsize-base-3xl;
  }

  #{--left-side-width}: $leftside-width;

  // swiper color
  #{--swiper-theme-color}: $primary-color !important;

  // Lib UI Component
  #{--buttonUI-border-radius}: $border-radius;

  #{--cardSimpleUI-box-shadow}: $box-shadow-light !important;
  #{--cardSimpleUI-border-radius}: $border-radius !important;

  #{--alertDialogUI-color-primary}: $primary-color;
  #{--alertDialogUI-color-error}: $error-color;
  #{--alertDialogUI-color-warning}: $warning-color;
  #{--alertDialogUI-color-secondary}: $secondary-color;
  #{--alertDialogUI-color-success}: $success-color;
  #{--alertDialogUI-color-info}: $info-color;
  #{--tableUI-hover-background-color}: #f2f2f2 !important;

  #{--cardModelUI-container-box-shadow}: $box-shadow-light !important;
  #{--tableUI-max-height}: initial !important;
}
