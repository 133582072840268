@import 'lib/core/styles/variables.module';

.number {
  white-space: nowrap;

  .input {
    border: none;
    font-size: 1em;
    text-align: right;
    -moz-appearance: textfield;
    margin-right: .5em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
