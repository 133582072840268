@import 'lib/core/styles/variables.module';

.dialog {
  background-color: $background-form-color;
}

.market-place-button {
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 1000;
  background-color: $primary-color;
  border: 2px solid white;
  border-radius: 100%;
  transition: background-color 150ms linear, border-color 150ms linear;
  box-shadow: $box-shadow;

  svg {
    margin: .2em;
    color: white;
    transition: color 150ms linear;
  }

  &:hover {
    background-color: white;
    border-color: $primary-color;

    svg {
      color: $primary-color;
    }
  }

  &.unpaid {
    background-color: $error-color;

    &:hover {
      background-color: white;
      border-color: $error-color;

      svg {
        color: $error-color;
      }
    }
  }

  &.incomplete {
    background-color: $info-color;

    &:hover {
      background-color: white;
      border-color: $info-color;

      svg {
        color: $info-color;
      }
    }
  }
}

.link {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.alert {
  box-shadow: $box-shadow-light2 !important;
}
