@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles.scss';
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: $background-form-color;
  font-size: var(--fontsize-base);
}

h1, h2, h3, h4, h5, h6 { @apply font-bold; }
h1 { @apply text-2xl text-primary; }
h2 { @apply text-xl; }
h3 { @apply text-lg; }
h4 { @apply text-base; }
h5 { @apply text-sm; }
h6 { @apply text-xs; }
a { @apply text-primary underline; }

h1 > .cardSimple-container {
  text-align: left;
}

p {
  margin: initial;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.tox {
  z-index: 1400 !important;
}
svg {
  display: initial;
}

// hack hotjar
#_hj_feedback_container {
  --hjFeedbackAccentColor: var(--primary-color) !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow:    inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius:         10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius:         10px;
  background:            rgba(196, 196, 196, 0.8);
  -webkit-box-shadow:    inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(196, 196, 196, 0.4);
}
//
//.MuiCalendarPicker-root {
//  width: 18vw !important;
//}
