@import 'lib/core/styles/variables.module';


.container {

  .highlight-content {
    color: $white-color;
    background-color: $primary-color;
    padding: .5em 0;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid $primary-color;
    border-radius: $border-radius $border-radius 0 0;
  }

  .title {
    font-weight: bold;
    color: $primary-color;
    font-size: 2em;
  }

  .price {
    margin: .5em 0 0;
    color: $grey-color;
    font-size: 1em;
  }
  .desc {
    //margin: 1em 6em;
    font-size: 1em;
    color: $grey-color;
  }

  .features {
    text-align: left;
    padding: 1em;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 1em 0;

        &:before {
          content: "●";
          margin-right: 1em;
          color: $primary-color;
        }
        &.white:before {
          color: $white-color
        }
      }
    }
  }

  .button {
    width: 100%;
    margin-top: 1em;
    text-align: center;

    button {
      width: 100%;
      padding: .8em;
    }
  }
  .bottom {
    width: 100%;
  }
  .action-button {
    font-weight: bold !important;
  }
}
