@import 'lib/core/styles/variables.module';

.chipUI {
  font-size: 0.8125rem;
  display: inline-flex;

  &.default {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08);
  }

  .close {
    width: 1.7em;
    display: flex;
    cursor: pointer;

    svg {
      fill: rgba(0, 0, 0, 0.26);

      &:hover {
        fill: rgba(0, 0, 0, 0.4);
      }
    }
  }
}