@import 'lib/core/styles/variables.module';

.container {
  box-shadow: $box-shadow-input;
  border-radius: $border-radius;
  background-color: $white-color;
  padding: 2em;
  align-items: center;

  .title {
    font-weight: bold;
    color: $primary-color;
    font-size: 2em;
    padding-right: 2em;
  }

  .desc {
    flex-grow: 1;
    padding: 0;
    color: $dark-color;

    @media (max-width: $breakpoint-md + px) {
      padding: 2em 0;
    }
  }

  .link {
    text-decoration: none;
  }
}
