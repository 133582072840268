:root {
  #{--buttonUI-background-color}: #004AAD;
  #{--buttonUI-font-weight}: bold;
  #{--buttonUI-border-radius}: .1em;

  #{--alertDialogUI-paper-background-color}: white;
  #{--alertDialogUI-color-primary}: #004AAD;
  #{--alertDialogUI-color-error}: #EB1818;
  #{--alertDialogUI-color-warning}: #FF922E;
  #{--alertDialogUI-color-secondary}: #A5C3FF;
  #{--alertDialogUI-color-success}: #2E7D32;
  #{--alertDialogUI-color-info}: #0288d1;

  #{--alertDialogUI-box-shadow-primary}: 0 0 1em rgba(var(--alertDialogUI-color-primary), 32%);
  #{--alertDialogUI-box-shadow-danger}: 0 0 1em rgba(var(--alertDialogUI-color-error), 32%);
  #{--alertDialogUI-box-shadow-warning}: 0 0 1em rgba(var(--alertDialogUI-color-warning), 32%);
  #{--alertDialogUI-box-shadow-secondary}: 0 0 1em rgba(var(--alertDialogUI-color-secondary), 32%);
  #{--alertDialogUI-box-shadow-success}: 0 0 1em rgba(var(--alertDialogUI-color-success), 32%);
  #{--alertDialogUI-box-shadow-info}: 0 0 1em rgba(var(--alertDialogUI-color-info), 32%);

  #{--cardSimpleUI-box-shadow}: 0 0 1em rgb(0 74 173 / 32%);
  #{--cardSimpleUI-background-color}: white;
  #{--cardSimpleUI-border-radius}: 1em;
  #{--cardSimpleUI-padding}: 1em;
  #{--cardSimpleUI-font-size}: 1.3em;

  #{--cardModelLoadingUI-container-font-size}: 20px;
  #{--cardModelLoadingUI-container-box-shadow}: 0 0 .5em rgb(0 74 173 / 32%);
  #{--cardModelLoadingUI-container-border-radius}: .5em;
  #{--cardModelLoadingUI-container-max-width}: 18em;
  #{--cardModelLoadingUI-content-background-color}: white;
  #{--cardModelLoadingUI-media-min-height}: 10em;

  #{--cardModelUI-container-font-size}: 20px;
  #{--cardModelUI-container-box-shadow}: 0 0 .5em rgb(0 74 173 / 32%);
  #{--cardModelUI-container-border-radius}: .5em;
  #{--cardModelUI-container-max-width}: 18em;
  #{--cardModelUI-content-background-color}: white;
  #{--cardModelUI-media-min-height}: 10em;
  #{--cardModelUI-title-color}: #004AAD;
  #{--cardModelUI-label-top}: #252A34;

  #{--tableUI-striped-background-color}: rgba(0, 0, 0, .05);
  #{--tableUI-hover-background-color}: rgba(0, 0, 0, .075);
  #{--tableUI-max-height}: 80vh;
}