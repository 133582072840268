@import 'lib/core/styles/variables.module';

.payment {
  padding-top: 0;

  div[role="tablist"] button {
    margin-top: 1em;
    text-transform: initial !important;
  }

  .desc {
    margin-top: 1em;
    font-size: .8em;
    color: $grey-color;
  }

  .container {
    padding-left: 0 !important;
    padding-top: 0;
    position: relative;


    .isStandard {
      margin-top: 2em;
    }
  }

  .container-tab {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .content-tab {
      position: relative;

      .offer {
        position: absolute;
        right: -9.3em;
        top: 0.5em;
        color: $primary-color;

        @media (max-width: $breakpoint-md + px) {
          right: -4em;
          top: initial;
          bottom: -1.8em;
        }

        .arrow {
          position: absolute;
          fill: $primary-color;
          left: -1.2em;
          top: -1em;
          transform: rotate(50deg);

          @media (max-width: $breakpoint-md + px) {
            right: 1em;
            top: -3em;
            transform: rotate(90deg);
            bottom: initial;
            left: initial;
          }
        }
      }

      .tabs-round {
        border: none;
        margin-bottom: 1em;
        position: relative;
        display: flex;

        .tab div[role=tablist] {
          flex-direction: row-reverse;

          button {
            margin-top: 0;
            background: white;
            color: $primary-color;
            border: 2px solid $primary-color;
            &:first-child {
              border-radius: 0 $border-radius $border-radius 0;
              border-left: none;
            }

            &:last-child {
              border-radius: $border-radius 0 0 $border-radius;
              border-right: none;
            }

            &[aria-selected=true] {
              background: $primary-color;
              color: white;
            }
          }
        }
      }
    }
  }

  .indicator {
    display: none;
  }

  &.autoFontSize {
    font-size: $fontsize-base-3xl !important;

    button {
      font-size: $fontsize-base-3xl !important;
    }

    .container-tab .content-tab .offer {
      span {
        font-size: $fontsize-base-xxl !important;
      }
      @media (max-width: $breakpoint-md + px) {
        right: -2em;
        bottom: -1.5em;
      }

      .arrow {
        @media (max-width: $breakpoint-md + px) {
          top: -2em;
          right: 0;
        }
      }
    }
  }
}